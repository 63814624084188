import React from "react";

import { Field } from "formik";
import { FormGroup, Label } from 'reactstrap';
import { Row, Col } from "antd";


import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

const PaymentBlock = ({ name }) => {
    const translate = useTranslation();

    return (
        <Row>
            <Col xs={24} lg={12}>
                <Label className="control-label">{ translate("MiscBy") }</Label>
            </Col>
            <Col xs={24} lg={12}>
                <FormGroup check className="radio">
                    <Field component={InputWithFeedback} type="radio" id="dueWith1" name={name}
                            value="Chèque"/>
                    <Label check className="form-check-label">{ translate("MiscCheck") }</Label>
                </FormGroup>
                <FormGroup check className="radio">
                    <Field component={InputWithFeedback} type="radio" id="dueWith2" name={name}
                            value="Carte bancaire"/>
                    <Label check className="form-check-label">{ translate("MiscCreditCard") }</Label>
                </FormGroup>
                <FormGroup check className="radio">
                    <Field component={InputWithFeedback} type="radio" id="dueWith3" name={name}
                            value="Virement"/>
                    <Label check className="form-check-label">{ translate("MiscBankTransfer") }</Label>
                </FormGroup>
                <FormGroup check className="checkbox">
                    <Field component={InputWithFeedback} type="radio" id="dueWith4" name={name}
                            value="autre"/>
                    <Label check className="form-check-label" htmlFor="checkbox4">{ translate("MiscOthers") }</Label>
                </FormGroup>
                <FormGroup>
                    <Field component={InputWithFeedback} type="text" name="other"/>
                </FormGroup>
            </Col>
        </Row>
    );
}

export default PaymentBlock;