import React from 'react';

import { Button, ButtonGroup, FormGroup, Label } from "reactstrap";
import { Row, Col } from "antd";
import { Field } from "formik";

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

function UploadComponent(props) {
    const translate = useTranslation();

    return (
        <Row>
            <Col xs={24}>
                <Label className={props.msgRequired === true ? "control-label" : ""}>{ translate("MiscMessage") }</Label>
            </Col>
            <Col xs={24}>
                <Field component={InputWithFeedback} style={{height: "250px"}} type="textarea" name="comment"/>
            </Col>
            <Col xs={24} className='mt-1'>
                <Label htmlFor="file-multiple-input">{ translate("MiscSubmitDocument") }</Label>
            </Col>
            <Col xs={24}>
                <Label for="file-multiple-input" className="m-0">{ translate("MiscSelectFile") }</Label>
                <span className="mx-2">|</span>
                {
                    props.files.length === 0 
                    ?
                        <Label for="file-multiple-input" className="m-0">{ translate("MiscNoFileSelected") }</Label>
                    :
                        <Label for="file-multiple-input" className="m-0">{ props.files.length + " " + translate("MiscFileSelected") }</Label>
                }
                <Field 
                    style={{ height: "1px", padding: "0", visibility: "hidden" }} 
                    component={InputWithFeedback} 
                    type="file" 
                    id="file-multiple-input"
                    name="file-multiple-input" 
                    onChange={props.addFiles} 
                    multiple
                />
            </Col>
            {
                props.files.map((file, i) => (
                    <Row className="mt-1 w-100" key={"file-input-" + i} align="middle" gutter={8}>
                        <Col key={"fileInputCol-" + i} xs={20}>
                            <Field 
                                component={InputWithFeedback} 
                                type="text" 
                                name={"fileInput-" + i}
                                value={file.newName !== "" ? file.newName : file.name}
                                onChange={(e) => {
                                    if (e.target.value !== "" && e.target.value !== null) {
                                        props.setFileNewName(e.target.value, i);
                                        props.setFieldValue("fileInput-" + i, e.target.value);
                                    }
                                }}>
                            </Field>
                        </Col>
                        <Col xs={4}>
                            <ButtonGroup>
                                <Button size="sm" style={{minWidth: '0'}} color="primary"
                                        onClick={() => {
                                            props.setCurrentFile(i);
                                            props.setShowModal(true)
                                        }}>
                                    <i className="fa fa-info fa-lg"/>
                                </Button>
                                <Button size="sm" style={{minWidth: '0'}} color="danger" onClick={() => {
                                    const newFiles = [...props.files];
                                    const index = newFiles.indexOf(file);
                                    if (index > -1) {
                                    newFiles.splice(index, 1);
                                    props.setFiles(newFiles);
                                    if (index === props.currentFile){
                                        props.setCurrentFile(null) ;
                                    }
                                    }
                                }}>
                                    <i className="fa fa-close fa-lg"/>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                ))
            }
        </Row>
  );

}

export default UploadComponent;