import React, { useState } from "react";

import { Field } from "formik";
import { Label } from 'reactstrap';
import { Row, Col, Space } from "antd";

import { isNonEmpty } from "Utils/Common";

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

const ContactDetailBlock = ({ variant, formExtraElements, setFieldValue }) => {
    const [ currentValue, setCurrentValue ] = useState("0");
    const [ currentDescElement, setCurrentDescElement ] = useState(null);
    const translate = useTranslation();

    const handleDescChange = ( name, value, formInputLabel, formDescElements ) => {
        setCurrentValue(value);
        value = value-1;
        setCurrentDescElement(formDescElements[value] ?? null);
        // setFieldValue(name, { [formInputLabel]: formDescElements[value] } ?? null);
        setFieldValue(name, { value: formDescElements[value], label: formInputLabel } ?? null);
    }

    return (
        <Space direction="vertical">
            <Row>
                <Col xs={24} lg={12}/>
                <Col xs={24} lg={12}>
                    <Field component={InputWithFeedback} disabled type="text" name="name"/>
                </Col>
            </Row>
            {
                formExtraElements && isNonEmpty(formExtraElements)
                &&
                formExtraElements.map((el, idx) => {
                    const { formInputLabel, formInputName, formInputType, formInputElements, formDescElements } = el;
                    return (
                        <Row align="middle" key={idx}>
                            {
                                formInputType === 'select' && isNonEmpty(formInputElements) && isNonEmpty(formDescElements) && formInputElements.length === formDescElements.length
                                ?
                                    <>
                                        <Col xs={24} lg={12}>
                                            <Label className="m-0">{ formInputLabel }</Label>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <Field component={InputWithFeedback} type={formInputType} name={formInputName} value={currentValue} onChange={({ currentTarget }) => handleDescChange(currentTarget.name, currentTarget.value, formInputLabel, formDescElements)}>
                                                <option value="0" disabled>-Selectionner-</option>
                                                {
                                                    formInputElements.map((e, i) => {
                                                        const { value, label } = e;

                                                        return (
                                                            <option key={i} value={value}>{ label }</option>
                                                        );
                                                    })
                                                }
                                            </Field>
                                        </Col>
                                    </>
                                :
                                ['text', 'email', 'date'].includes(formInputType)
                                &&
                                    <>
                                        <Col xs={24} lg={12}>
                                            <Label className="m-0">{ formInputLabel }</Label>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <Field component={InputWithFeedback} type={formInputType} name={formInputName} />
                                        </Col>
                                    </>

                            }
                            { 
                                currentDescElement 
                                ? 
                                    <Col className="d-flex justify-content-end" style={{ fontStyle: "italic" }}>
                                        { currentDescElement }
                                    </Col>
                                : 
                                null 
                            }
                        </Row>
                    );
                })
            }
            <Row align="middle">
                <Col xs={24} lg={12}>
                    <Label>{ translate("MiscContactDetails") }</Label>
                </Col>
                <Col xs={24} lg={12}>
                    <Field component={InputWithFeedback} type="text" name="newName"/>
                </Col>
            </Row>
            <Row align="middle">
                <Col xs={24} lg={12}>
                    <Label className="control-label">{ translate("MiscTelephoneNumber") }</Label>
                </Col>
                <Col xs={24} lg={12}>
                    <Field component={InputWithFeedback} type="text" name="tel"/>
                </Col>
            </Row>
            {
                variant
                ?
                    <>
                        <Row align="middle">
                            <Col xs={24} lg={12}>
                                <Label className="control-label">{ translate("NewMessageDate") }</Label>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Field component={InputWithFeedback} type="date" name="prefDay"/>
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col xs={24} lg={12}>
                                <Label>{ translate("NewMessageTimeSlot") }</Label>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Field component={InputWithFeedback} type="select" as="select" name="prefHour">
                                    <option value="8h-12h">{ translate("NewMessage8To12") }</option>
                                    <option value="12h-14h">{ translate("NewMessage12To14") }</option>
                                    <option value="14h-18h">{ translate("NewMessage14To18") }</option>
                                    <option value="18h-20h">{ translate("NewMessage18To20") }</option>
                                </Field>
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col xs={24} lg={12}>
                                <small style={{fontStyle: "italic"}}>{ translate("NewMessageEndeavorToContactYou") }</small>
                            </Col>
                            <Col xs={24} lg={12}/>
                        </Row>
                    </>
                :
                    <Row align="middle">
                        <Col xs={24} lg={12}>
                            <Label>{ translate("MiscContactPreferences") }</Label>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Field component={InputWithFeedback} type="text" name="prefContact"/>
                        </Col>
                    </Row>
            }
            <Row align="middle">
                <Col xs={24} lg={12}>
                    <Label className="control-label">{ translate("MiscEmail") }</Label>
                </Col>
                <Col xs={24} lg={12}>
                    <Field component={InputWithFeedback} type="text" name="email"/>
                </Col>
            </Row>
        </Space>
    );
}

export default ContactDetailBlock;