import React, { useState, useEffect, useMemo } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { 
    Button,
    Card, 
    Col, 
    Row
} from "antd";

import { Loading } from "components";
import { useTranslation, useApp } from "contexts";
import { isNonEmpty, isEmpty } from 'Utils/Common';

import Payer from "./Modal/Payer";
import Table from './AccueilTable';

import './Accueil.css';

const Accueil = ({ ...props }) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ showModalAfterpay, setShowModalAfterpay ] = useState(false);
    const [ factAmountSelected, setFactAmountSelected ] = useState(0);
    const [ factSelected, setFactSelected ] = useState([]);

    const navigate = useNavigate();
    const translate = useTranslation();
    const { config } = useApp();
    const { accueilParam1, accueilParam2 } = useParams();

    useEffect(() => {
        if( accueilParam1 && accueilParam1 === "afterpay" ) {
            navigate("/accueil") ; //HACK
            //TODO : remove query ? 
            setShowModal(true);
            setShowModalAfterpay(accueilParam2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accueilParam1, accueilParam2])

    const handleModal = ( torf ) => {
        let newState ;

        if( torf != null ) {
           newState = torf ;
        } else {
            newState = !showModal ;
        }

        setShowModal(newState);
        setShowModalAfterpay(false);
    }

    const handleModalOpen = () => {
		handleModal(true) ;
    }

    const handleModelEnd = () => {
        handleModal(false) ;
    }

    const onRowSelection = ( isSelected, row, isMultiple = false ) => {
        let amount = 0;
        let indexes = [] ;

        if (isMultiple === true){
            row.forEach((rec) => {
                let tmpAmount = rec.amount.toString().replace(/\s/g,'') ;
                tmpAmount = tmpAmount.replace(",",'.') ;
                tmpAmount = parseFloat(tmpAmount) ;
                amount += tmpAmount ;
            })
        } else {
            amount = row.amount.toString().replace(/\s/g,'') ;
            amount = amount.replace(",",'.') ;
            amount = parseFloat(amount) ;
        }

        if( amount <= 0 ) return ;

        if( isSelected === true ) {
            let res = factAmountSelected + amount ;
            res = parseFloat(res.toFixed(2)) ;

            let newFactSelect = [...factSelected] ;

            if( isMultiple === true ){
                row.forEach((rec) => {
                    newFactSelect.push(rec) ;
                }) ;
            } else {
                newFactSelect.push(row) ;
            }

            setFactAmountSelected(res);
            setFactSelected(newFactSelect);
        } else {
            if( amount < 0 ) amount = 0 ;

            let res = factAmountSelected - amount ;
            res = parseFloat(res.toFixed(2)) ;

            let newFactSelect = [...factSelected] ;
            let index ;

            newFactSelect.forEach((fact, i) => {
                if( isMultiple === true ) {
                    row.forEach((rec) => {
                        if( fact["record_ref"] === rec["record_ref"] ) {
                            indexes.push(i) ;
                        }
                    })
                } else {
                    if( fact["record_ref"] === row["record_ref"] ) {
                        index = i ;
                    }
                }
            })

            if( isMultiple !== true ) {
                newFactSelect.splice(index, 1) ;
            } else {
                while( indexes.length ) {
                    newFactSelect.splice(indexes.pop(), 1);
                }
            }

            setFactAmountSelected(res);
            setFactSelected(newFactSelect);
        }
    }

    const resetAmount = () => {
        setFactAmountSelected(0);
    }

    const leftPartStyles = useMemo(() => ({ fontSize: '1rem' }), []);
    const midPartTitleStyles = useMemo(() => ({ fontSize: '1.25rem', fontWeight: '700' }), []);
    const midPartStyles = useMemo(() => ({ fontSize: '1.15rem', fontWeight: '400' }), []);

    return (
        <>
            {
                isNonEmpty(config)
                ?
                    <>
                        <Row gutter={[12, 12]} className="mb-3">
                            <Col xs={24} lg={6}>
                                <Card 
                                    style={{
                                        minHeight: '15vh',
                                        border: '1px solid #2eadd3',
                                        backgroundColor: '#63c2de',
                                        color: 'white',
                                    }}
                                >
                                    <div 
                                        className="d-flex justify-content-center"
                                        style={{
                                            fontSize: '1.3rem', 
                                            fontWeight: '600'
                                        }}
                                    >
                                        { translate("HomeDueAmount") } (€)
                                    </div>
                                    
                                    <div 
                                        className="d-flex mx-auto"
                                        style={{
                                            fontWeight: '600', 
                                            display: 'flex'
                                        }}
                                    >
                                        <div 
                                            style={{
                                                margin: 'auto', 
                                                fontSize: '1.8rem'
                                            }}
                                        >
                                            {/* {new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(config["amountDue"])} */}
                                            { config["amountDue"] }
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Card 
                                    style={{
                                        minHeight: '15vh',
                                        border: '1px solid #379457',
                                        backgroundColor: '#4dbd74',
                                        color: 'white',
                                    }}
                                >
                                    <Row align="middle">
                                        <Col xs={24} lg={9} className="d-flex justify-content-center align-self-start" style={midPartTitleStyles}>{ translate("HomeYourCreditor") }</Col>
                                        <Col xs={24} lg={15} className="text-break" style={midPartStyles}>{ config["socTxt"] }</Col>
                                    </Row>
                                    <Row align="middle">
                                        <Col xs={24} lg={9} className="d-flex justify-content-center" style={midPartTitleStyles}>{ translate("HomeYourClientRef") }</Col>
                                        <Col xs={24} lg={15} style={midPartStyles}>{ config["accId"] }</Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} lg={6}>
                                <Row
                                    gutter={[8,8]}
                                    justify="center"
                                    className="mb-xs-0 pt-0"
                                >
                                    <Col xs={24}>
                                        <Button 
                                            type="primary" 
                                            className="w-100"
                                            style={{ backgroundColor:"#63c2de", padding:"5px", height:"48px" }}
                                            onClick={handleModalOpen}
                                        >
                                            <Row align="middle">
                                                <Col xs={18} lg={24} style={leftPartStyles}>{ translate("HomePayment") }</Col>
                                                { 
                                                    factAmountSelected > 0 
                                                    ? 
                                                        <Col xs={6} lg={24} style={leftPartStyles}>
                                                            { "(" + new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(factAmountSelected) + " €)" }
                                                        </Col>
                                                    : 
                                                        null
                                                }
                                            </Row>
                                        </Button>
                                    </Col>
                                    <Col xs={24}>
                                        <Button 
                                            type="primary" 
                                            className="w-100"
                                            style={{ backgroundColor:"#63c2de", padding:"5px", height:"48px" }}
                                            onClick={() => {
                                                if( factSelected.length === 0 ) {
                                                    navigate("/nouveauMessage");
                                                } else {
                                                    navigate("/nouveauMessage", { state: { factSelected } });
                                                }
                                            }}
                                        >
                                            <div style={leftPartStyles}>
                                                { translate("MiscNewMessage") }
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Payer 
                                isOpen={showModal} 
                                onModalEnd={handleModelEnd}
                                afterPay={!!showModalAfterpay} 
                                afterPayCode={showModalAfterpay}
                                accId={config["accId"]}
                                payAmount={
                                    factAmountSelected > 0 
                                    ?
                                        factAmountSelected 
                                    : 
                                        config["amountDue"]
                                }
                            />
                        </Row>
                        <Card
                            title={(
                                <strong>{ translate("HomeYourInvoices") }</strong>
                            )}
                            styles={{
                                body: {
                                    padding: '14px 10px'
                                }
                            }}
                        >
                            {
                                config?.["records"]
                                &&
                                    isEmpty(config["records"])
                                    ?
                                        <div style={{ margin: "50px 0", display: "flex", justifyContent: "center" }}>{ translate("HomeNothingHere") }</div>
                                    :
                                        <Table records={config["records"]} handleSelect={onRowSelection} resetAmount={resetAmount} isPopup={false} />
                            }
                        </Card>
                    </>
                :
                    <Loading />
            }
        </>
    );
}

export default Accueil;