import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from "formik";

import { useTranslation } from "contexts";
import { usePrevious } from "hooks";
import { createPostRequest } from 'Utils/Common' ;

import PayerForm from './PayerForm' ;
import PayerVendor from './PayerVendor' ;
import PayerAlert from './PayerAlert' ;

import './Payer.scss';

const Payer = ({ ...props }) => {
    const { isOpen, onModalEnd, afterPay, afterPayCode, accId, payAmount } = props;

    const [ loading, setLoading ] = useState(true);
    // const [ records, setRecords ] = useState(null);
    const [ transactionStep, setTransactionStep ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ errorColor, setErrorColor ] = useState(null);

    const [ formValues, setFormValues ] = useState({});

    const [ vendorFormConfig, setVendorFormConfig ] = useState({});

    const [ modalTitle, setModalTitle ] = useState();

    const isOpenPrev = usePrevious(isOpen);
    const translate = useTranslation();

    const formikRef = useRef();

    useEffect(() => {
        if( !isOpen ) return ;
        if( isOpen !== isOpenPrev ){
            return afterPay ? afterTransaction(afterPayCode) : beginTransaction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, isOpenPrev, afterPay, afterPayCode])

    useEffect(() => {
        let title = null;
        if( isOpen ){
            switch(transactionStep) {
                case '1_form' : 
                case 'E_alert' : title = translate("HomeModalPaidFormAboutMakePayment"); break ;
            }
        }
        setModalTitle(title);
    }, [isOpen, transactionStep])

    const afterTransaction = (afterPayCode) => {
		setLoading(true);
		setTimeout(() => {
			afterTransactionDo(afterPayCode) ;
		}, 2000);
	}

	const afterTransactionDo = (afterPayCode) => {
		createPostRequest("pay_getAfterpayStatus")
			.then(response => {
				let ajaxData = response.data ;
				if (ajaxData.success === false) {
                    setLoading(false);
                    setTransactionStep('E_alert');
                    setErrorMessage(translate("HomeModalPaidErrorHasOccurred") + "\n" + translate("HomeModalPaidPleaseTryAgainLater"));
					return ;
				}
				let errMsg, errColor ;
				switch( afterPayCode ) {
					case 'OK' :
						switch( ajaxData.pay_status ) {
							case 'OK' :
								errMsg = translate("MiscYourPaymentOf") + " " + ajaxData.pay_amount + " " + ajaxData.pay_currency + " " + translate("MiscIsConfirmed").toLowerCase() + ".\n" + translate("MiscWeThankYou") + "." ;
								errColor = 'green' ;
								break ;
							default :
								errMsg = translate("HomeModalPaidPaymentAwaitingReceipt") + " " + translate("HomeModalPaidPleaseLogBack")
								errColor = 'green' ;
								break ;
						}
						break ;
					case 'KO' :
						switch( ajaxData.pay_status ) {
							case 'OK' :
								errMsg = translate("MiscYourPaymentOf") + " " + ajaxData.pay_amount + " " + ajaxData.pay_currency + " " + translate("MiscIsConfirmed").toLowerCase() + ".\n" + translate("MiscWeThankYou") + "." ;
								errColor = 'green' ;
								break ;
							case 'FAIL' :
								errMsg = translate("HomeModalPaidPaymentFailed") + " " +  translate("HomeModalPaidPleaseRepeatOperationOrContactUs") ;
								errColor = 'red' ;
								break ;
							case 'NONE' :
								errMsg =  translate("HomeModalPaidOperationCancelled")  ;
								errColor = 'red' ;
								break ;
							default :
								errMsg = translate("HomeModalPaidErrorHasOccurred") + " " + translate("HomeModalPaidPleaseContactUs") ;
								errColor = 'red' ;
								break ;
						}
						break ;
					default :
						errMsg = translate("HomeModalPaidErrorHasOccurred") + " " +  translate("HomeModalPaidPleaseContactUs") ;
						errColor = 'red' ;
						break ;
				}
                setLoading(false);
                setTransactionStep('E_alert');
                setErrorMessage(errMsg);
                setErrorColor(errColor);
			})
			.catch(function (error) {
				console.log(error) ;
			});
		
	}
	
	const beginTransaction = () => {
        setLoading(true);
		createPostRequest("pay_pre_getAdr")
			.then(response => {
				let ajaxData = response.data ;
				if (ajaxData.success !== true) {
                    setLoading(false);
                    setTransactionStep('E_alert');
                    setErrorMessage(translate("HomeModalPaidErrorHasOccurred") + "\n" + translate("HomeModalPaidPleaseTryAgainLater"));
					return ;
				}
				let fValues = {
					pay_amount: payAmount
				}
				Object.assign(fValues,ajaxData.data) ;
                setLoading(false);
                setTransactionStep('1_form');
                setFormValues(fValues);
			})
			.catch(function (error) {
				console.log(error) ;
			});
		/*
		let timerid = setTimeout(() => {
		}, 2000);
		*/
	}

	const handleFormAction = (form,actionId,values) => {
		switch( actionId ) {
			case 'submit' :
				return handleFormSubmit(values);
			case 'cancel' :
				return onModalEnd({ button:'form_cancel' })
			default :
				console.dir(values);
		}
	}
	
	const handleFormSubmit = (values) => {
        setLoading(true);
		let postValues = Object.assign({
			_url: window.location.href,
			acc_id: accId
		},values) ;
		createPostRequest("pay_getVendorForm",postValues)
			.then(response => {
				let ajaxData = response.data ;
				// console.dir(response) ;
				if( ajaxData.success === false ) {
                    setLoading(false);
                    setTransactionStep('E_alert');
                    setErrorMessage(translate("HomeModalPaidErrorHasOccurred") + "\n" + translate("HomeModalPaidPleaseTryAgainLater"));
					return ;
				}
                setLoading(false);
                setTransactionStep('2_vendor');
                setVendorFormConfig(ajaxData.form);
			})
			.catch(function (error) {
				console.log(error) ;
			});
	}

	// const handleFormCancel = (form) => {
	//     onModalEnd({ button:'form_cancel' }) ;
	// }

	// const handleFormDebug = (form) => {
	// 	//this.forceUpdate() ;
    //     setLoading(false);
    //     setFormValues({
    //         ...formValues,
    //         pay_amount: (formValues?.['pay_amount'] - 1),
    //         pay_comment: 'Commentaire...'
    //     })
	// }

    const getFormik = () => {
        return formikRef.current ;
    }

    const handlePayerFormButton = (actionId) => {
        switch( actionId ) {
            case 'submit' :
                return getFormik().submitForm() ;
            case 'reset' :
                return getFormik().resetForm() ;
            default :
                break ;
        }
        handleFormAction(this,actionId,getFormik().values) ;
    }

    const handlePayerAlertButton = (buttonId) => {
		switch( buttonId ) {
			case 'close' :
				onModalEnd({ button:'error_end' }) ;
				break ;
			default :
				break ;
		}
	}

    const renderModalFooterButtons = () => {
        switch( transactionStep ) {
            case '1_form':
                return (
                    <>
                        <Button type="primary" onClick={() => handlePayerFormButton("submit")}>{ translate("MiscContinue") }</Button>
                        <Button type="primary" style={{ display:'none' }} onClick={() => handlePayerFormButton("reset")}>{ translate("MiscReset") }</Button>
                        <Button type="primary" danger onClick={() => handlePayerFormButton("cancel")}>{ translate("MiscAbort") }</Button>
                    </>
                );
            case 'E_alert' :
                return (
                    <Button type="primary" danger onClick={() => handlePayerAlertButton("close")}>{ translate("MiscClose") }</Button>
                );
        }
    }

    const fields = useMemo(() => ([
        'pay_amount',
        'pay_comment',
        'adr_nom',
        'adr_adr1',
        'adr_adr2',
        'adr_cp',
        'adr_ville',
        'adr_pays',
        'adr_tel',
        'adr_email'
    ]), []) ;
    const fieldsMandatory = useMemo(() => ([
        'adr_nom',
        'adr_adr1',
        'adr_cp',
        'adr_ville',
        'adr_pays',
        'adr_tel',
        'adr_email'
    ]), []) ;

    const isEmpty = (str) => {
        return (!str || 0 === str.length);
    }

    const getInitialValues = () => {
        let initialValues = {} ;
        fields.forEach(element => {
            if( formValues[element] ) {
                initialValues[element] = formValues[element] ;
                return ;
            }
            initialValues[element] = '' ;
        }) ;
        return initialValues ;
    }

    const handleSubmit = (values) => {
        handleFormAction(this,'submit',values) ;
    }

    const doFormikValidate = (values) => {
        const errors = {};
        if( (values.pay_amount > 0) && (values.pay_amount <= formValues['pay_amount']) ) {} else {
            errors.pay_amount =  translate("HomeAmount") + " " + translate("MiscIncorrect").toLowerCase() ;
        }
        /*
        if( !(RegExp(/^(0|\+33)[0-9]{9}$/).test(values.adr_tel)) ) {
            errors.adr_tel = 'Numéro de téléphone incorrect';
        }
        */
        if( !(RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(values.adr_email)) ) {
            errors.adr_email =  translate("MiscEmail") + " " + translate("MiscIncorrect").toLowerCase() ;
        }
        fieldsMandatory.forEach( mkey => {
            if (isEmpty(values[mkey])) {
                errors[mkey] = translate("MiscToInform") ;
            }
        });
        return errors;
    }

    const renderModal = useCallback(( ) => {
        switch( transactionStep ) {
            case '1_form':
                return (
                    <Formik
                        innerRef={formikRef}
                        initialValues={getInitialValues()}
                        onSubmit={handleSubmit}
                        validate={doFormikValidate}
                        onReset={() => {}}
                    >
                        <PayerForm formValues={formValues} onFormAction={handleFormAction} />
                    </Formik>
                ) ;
            case '2_vendor':
                return (<PayerVendor formConfig={vendorFormConfig} onFormAction={handleFormAction} />) ;
            case 'E_alert' :
                return (<PayerAlert errorMessage={errorMessage} errorColor={errorColor} />) ;
            default:
                return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        transactionStep, 
        formValues, 
        vendorFormConfig, 
        errorMessage, 
        errorColor,
        formikRef
    ])

    const modalWidths = useMemo(() => ({
        xs: '100%',
        sm: '100%',
        md: '80%',
        lg: '80%',
        xl: '80%',
        xxl: '80%'
    }), [])

    return (
        <Modal
            title={modalTitle}
            centered
            loading={loading}
            open={isOpen}
            onCancel={() => handlePayerFormButton("cancel")}
            footer={renderModalFooterButtons}
            width={modalWidths}          
        >
            { renderModal() }
        </Modal>
    );
}

export default Payer;