import React, {useState} from "react";

import { Field, Form, Formik } from "formik";
import { Button, FormGroup, Label } from 'reactstrap';
import { Col, Row, Space } from "antd";
import * as Yup from "yup";

import { InputWithFeedback } from "components";
import { useTranslation, useApp } from "contexts";
import { createPostRequest, isNonEmpty } from "Utils/Common";

import UploadComponent from "./UploadComponent";

import AddCommentModal from "views/Recouveo/NouveauMessage/Modals/AddCommentModal";
import ConfirmModal from "views/Recouveo/NouveauMessage/Modals/ConfirmModal";
import ErrorModal from "views/Recouveo/Modals/ErrorModal";

import ContactDetailBlock from "./_ContactDetailBlock";

const AskDocument = ({ ...props }) => {
    const { factSelected, uploadFiles, addResult, addFiles, formSent } = props ;

    const [ files, setFiles ] = useState([]) ;
    const [ showModal, setShowModal ] = useState(false) ;
    const [ currentFile, setCurrentFile ] = useState(null) ;
    const [ showError, setErr ] = useState(false) ;
    const [ errorTxt, setErrTxt ] = useState(null) ;
    const [ showConfirmModal, setConfirmModal ] = useState(false) ;

    const translate = useTranslation();
    const { config } = useApp();

    const [ checkboxVals, setCheckboxVals ] = useState(Array(4).fill(false))

    const addFilesToState = (e) => {
        setFiles(addFiles(e, files)) ;
    }

    const setFileNewName = (name, i) => {
        if (files[i] === undefined || files[i] === null) return ;
        files[i].newName = name ;
    }

    const validationSchema = Yup.object().shape({
        tel: Yup
          .string()
          .required( translate("MiscFieldRequired") )
          .matches("^(\\+?\\d{0,4})?\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{4}\\)?)$", translate("MiscTelephoneNumberMustBeValid")),
        email: Yup
          .string()
          .required( translate("MiscFieldRequired") )
          .matches("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$", translate("MiscEmailAddressMustBeValid")),
    })

    return (
        <>
            {
                isNonEmpty(config) &&
                    <div className={"form"}>
                        <Formik
                            initialValues={{
                                checkbox1: [],
                                checkbox2: [],
                                checkbox3: [],
                                checkbox4: [],
                                other: "",
                                tel: config["tel"].replace(/\D/g, ''),
                                email: config["email"],
                                comment: "",
                                prefContact: "",
                                name: config["accName"],
                                newName: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                let fichiers = "" ;
                                if( isNonEmpty(values.checkbox1) ) {
                                    fichiers += translate("NewMessageInvoiceCopy") + ", "
                                }
                                if( isNonEmpty(values.checkbox2) ) {
                                    fichiers += translate("NewMessageAccountStatement") + ", "
                                }
                                if( isNonEmpty(values.checkbox3) ) {
                                    fichiers += translate("NewMessageIban") + ", "
                                }
                                if( isNonEmpty(values.checkbox4) ) {
                                    fichiers += values.other + ", "
                                }
                                fichiers = fichiers.slice(0, -2) ;
                                let title = translate("NewMessageRequestDocuments") ;
                                let text =
                                    translate("NewMessageContactIdentityCompleted") + ": " + values.newName + "\r\n" +
                                    translate("MiscTelephoneNumber") + ": " + values.tel + "\r\n" +
                                    translate("MiscContactPreferencesShort") + ": " + values.prefContact + "\r\n" +
                                    translate("MiscEmail") + ": " + values.email + "\r\n" +
                                    translate("MiscDocument") + ": " + fichiers + "\r\n" +
                                    translate("NewMessageComment") + ": " + values.comment + "\r\n" ;
                                if( isNonEmpty(factSelected) ){
                                    text += translate("NewMessageAffectedInvoices") + ": " ;
                                        factSelected.forEach((rec) => {
                                    text += rec["record_ref"] + ", " ;
                                    })
                                    text = text.slice(0, -2) ;
                                    text += "\r\n" ;
                                }
                                let error = false ;
                                if( isNonEmpty(files) ) {
                                    uploadFiles(files).then(response => {
                                    response.forEach((res, i) => {
                                        if (res.data !== null && res.data.success !== true) {
                                        error = true ;
                                        }
                                    });
                                    if (error === true){
                                            formSent("-1") ;
                                        return ;
                                    }
                                    text = text + translate("NewMessageAttachments") + ": \r\n";
                                    files.forEach((file, i) => {
                                        if (file.newName !== "") text = text + file.newName + "\r\n";
                                        else text = text + file.name + "\r\n";

                                    });
                                    let msgAction = {
                                        "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                        "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                    };
                                    createPostRequest("tchat_post_formulaire", {
                                        "file_filerecord_id": config["fileOpenFilerecordId"],
                                        "data": JSON.stringify(msgAction)
                                    }).then(response => {
                                        if (response.data.success !== true) {
                                            formSent("-1") ;
                                        } else formSent("7") ;

                                    })

                                    }).catch(err => {
                                    error = true ;
                                        addResult(false) ;
                                        formSent("-1");
                                    });
                                } else {
                                    let msgAction = {
                                    "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                    "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                    };
                                    createPostRequest("tchat_post_formulaire", {
                                    "file_filerecord_id": config["fileOpenFilerecordId"],
                                    "data": JSON.stringify(msgAction)
                                    }).then(response => {
                                    if (response.data.success !== true) {
                                        formSent("-1") ;
                                    } else formSent("7") ;
                                    })
                                }
                            }}
                            onReset={values => {
                                props.onReset() ;
                            }}>
                            {({
                                setFieldValue,
                                submitForm,
                                values,
                            }) => (
                                <Form>
                                    <AddCommentModal 
                                        isOpen={showModal} 
                                        content={currentFile !== null ? files[currentFile].comment : ""} 
                                        toggle={() => setShowModal(!showModal)}
                                        onChange={(e) => {
                                            if (e === null) {
                                                setShowModal(false);
                                                return;
                                            }
                                            files[currentFile].comment = e.target.value;
                                        }}
                                    />
                                    <ErrorModal isOpen={showError} error={errorTxt} toggle={() => setErr(!showError)}/>
                                    <ConfirmModal 
                                        isOpen={showConfirmModal} 
                                        toggle={() => setConfirmModal(!showConfirmModal)} 
                                        validate={() => {
                                            setConfirmModal(false) ;
                                            submitForm() ;
                                        }}
                                    />
                                    <Row gutter={[16,16]}>
                                        <Col xs={24} lg={12}>
                                            <Space direction="vertical">
                                                <Row>
                                                    <Col xs={24} lg={12}>
                                                        <Label className="control-label">{ translate("NewMessageDocumentType") }</Label>
                                                    </Col>
                                                    <Col xs={24} lg={12}>
                                                        <FormGroup check className="checkbox">
                                                            <Field component={InputWithFeedback} type="checkbox" id="checkbox1" name="checkbox1" value="0" onChange={(e) => {
                                                                let arr = [...checkboxVals] ;
                                                                if (values.checkbox1.length > 0){
                                                                setFieldValue("checkbox1", []) ;
                                                                arr[parseInt(e.target.value)] = false ;
                                                                } else{
                                                                setFieldValue("checkbox1", [e.target.value]) ;
                                                                arr[parseInt(e.target.value)] = true ;
                                                                }
                                                                setCheckboxVals(arr) ;
                                                            }} />
                                                            <Label check className="form-check-label" htmlFor="checkbox1">{ translate("NewMessageInvoiceCopy") }</Label>
                                                        </FormGroup>
                                                        <FormGroup check className="checkbox">
                                                            <Field component={InputWithFeedback} type="checkbox" id="checkbox2" name="checkbox2" value="1"  onChange={(e) => {
                                                                let arr = [...checkboxVals] ;
                                                                if (values.checkbox2.length > 0){
                                                                setFieldValue("checkbox2", []) ;
                                                                arr[parseInt(e.target.value)] = false ;
                                                                } else{
                                                                setFieldValue("checkbox2", [e.target.value]) ;
                                                                arr[parseInt(e.target.value)] = true ;
                                                                }
                                                                setCheckboxVals(arr) ;
                                                            }}/>
                                                            <Label check className="form-check-label" htmlFor="checkbox2">{ translate("NewMessageAccountStatement") }</Label>
                                                        </FormGroup>
                                                        <FormGroup check className="checkbox">
                                                            <Field component={InputWithFeedback} type="checkbox" id="checkbox3" name="checkbox3" value="2"  onChange={(e) => {
                                                                let arr = [...checkboxVals] ;
                                                                if (values.checkbox3.length > 0){
                                                                setFieldValue("checkbox3", []) ;
                                                                arr[parseInt(e.target.value)] = false ;
                                                                } else{
                                                                setFieldValue("checkbox3", [e.target.value]) ;
                                                                arr[parseInt(e.target.value)] = true ;
                                                                }
                                                                setCheckboxVals(arr) ;
                                                            }}/>
                                                            <Label check className="form-check-label" htmlFor="checkbox3">{ translate("NewMessageIban") }</Label>
                                                        </FormGroup>
                                                        <FormGroup check className="checkbox">
                                                            <Field component={InputWithFeedback} type="checkbox" id="checkbox4" name="checkbox4" value="3"  onChange={(e) => {
                                                                let arr = [...checkboxVals] ;
                                                                if (values.checkbox4.length > 0){
                                                                setFieldValue("checkbox4", []) ;
                                                                arr[parseInt(e.target.value)] = false ;
                                                                } else{
                                                                setFieldValue("checkbox4", [e.target.value]) ;
                                                                arr[parseInt(e.target.value)] = true ;
                                                                }
                                                                setCheckboxVals(arr) ;
                                                            }}/>
                                                            <Label check className="form-check-label" htmlFor="checkbox4">{ translate("MiscOthers") }</Label>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Field component={InputWithFeedback} type="text" name="other"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <ContactDetailBlock />
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <UploadComponent 
                                                files={files} 
                                                msgRequired={true} 
                                                errors={showError} 
                                                addFiles={(e) => {
                                                    if (e.target.files.length > 3 || files.length + e.target.files.length > 3){
                                                        setErrTxt( translate("NewMessageModalTooMuchFiles") ) ;
                                                        setErr(true) ;
                                                        return ;
                                                    }
                                                    let issue = false ;
                                                    const filesUploaded = Object.values(e.target.files);
                                                    filesUploaded.forEach((file, i) => {
                                                        if( file.size >= 10000000 ){
                                                            setErrTxt( translate("NewMessageModalTooMuchSize") )
                                                            setErr(true) ;
                                                            issue = true ;
                                                        }
                                                    }) ;
                                                    if (issue === true ) return ;
                                                    addFilesToState(e) ;
                                                    setFieldValue("files", files) ;
                                                }} 
                                                setFileNewName={setFileNewName} 
                                                setFiles={setFiles} 
                                                setFieldValue={setFieldValue}
                                                setShowModal={setShowModal} 
                                                setCurrentFile={setCurrentFile} 
                                                currentFile={currentFile}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{width: '100%', margin: '0px auto', marginTop: '10px'}} className="text-center">
                                            <div style={{color: "red"}}>{ translate("MiscFieldsRequired") }</div>
                                            <br/>
                                            <Button 
                                                color="primary" 
                                                onClick={() => {
                                                    if (values.checkbox1.length === 0 && values.checkbox2.length === 0 && values.checkbox3.length === 0 && values.checkbox4.length === 0){
                                                        setErrTxt( translate("NewMessageCheckOneTypeOfDocument") ) ;
                                                        setErr(true) ;
                                                        return ;
                                                    }
                                                    if (values.checkbox4.length > 0 && values.other === ""){
                                                        setErrTxt( translate("NewMessageEnterADocumentType") ) ;
                                                        setErr(true) ;
                                                        return ;
                                                    }
                                                    if (values.comment === ""){
                                                        setErrTxt( translate("NewMessageFillMessageField") ) ;
                                                        setErr(true) ;
                                                        return ;
                                                    }
                                                    setConfirmModal(true) ;
                                                }}
                                            >
                                                { translate("MiscSendMessage") }
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
            }
        </>
  )
}
export default AskDocument;
