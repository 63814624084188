import React, { useMemo, useRef, useEffect, useState } from 'react';

import { Checkbox, Table } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useTranslate, useTranslation } from "contexts";

dayjs.extend(customParseFormat);

const AccueilTable = ({ ...props }) => {
    const { records, handleSelect, isPopup } = props;

    const [ scrollY, setScrollY ] = useState(window.innerHeight * 0.53); // Hauteur de défilement par défaut 53vh

    const tableContainerRef = useRef(null);
    const { translations, lang } = useTranslate();
    const translate = useTranslation();

    useEffect(() => {
        if (tableContainerRef.current) {
            const tableBody = tableContainerRef.current.querySelector('.ant-table-tbody');
            if (tableBody) {
                const contentHeight = tableBody.scrollHeight;
                if (contentHeight < window.innerHeight * 0.53) {
                    setScrollY(undefined);
                } else {
                    setScrollY(window.innerHeight * 0.53);
                }
            }
        }
    }, [records]);

    const columns = useMemo(() => ([
        {
            title: translate("HomeDate"),
            dataIndex: 'date_record',
            width: '6%',
            minWidth: 90,
            align: 'center',    
            ellipsis: true, // Tronque les valeurs longues par ...
            showSorterTooltip: false,
            sorter: (a, b) => dayjs(a.date_record).unix() - dayjs(b.date_record).unix(),
            render: (text) => dayjs(text).format('DD/MM/YY')
        },{
            title: translate("HomeReference"),
            dataIndex: 'record_ref',
            width: '80%',
            minWidth: 120,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: (a, b) => a.record_ref.localeCompare(b.record_ref),
            onHeaderCell: () => ({
                className: 'header-center',
            })
        },{
            title: translate("HomeAmount"),
            dataIndex: 'amount',
            width: '12%',
            minWidth: 110,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: (a, b) => a.amount - b.amount,
            onHeaderCell: () => ({
                className: 'header-center',
            }),
            onCell: () => ({
                className: 'cell-right',
            }),   
            render: (value) => Number(value).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    ]), [translations, lang]);
    const defaultCheckedList = useMemo(() => columns.map((item) => item.dataIndex),[columns]);

    const [ checkedList, setCheckedList ] = useState(defaultCheckedList);

    const options = columns.map(({ dataIndex, title }) => ({
        label: title,
        value: dataIndex,
    }));
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.dataIndex),
    }));

    const rowSelection = useMemo(() => ({
		type: 'checkbox',
        columnWidth: '2%',
		onSelect: ( record, selected, selectedRows, nativeEvent ) => {
			if( isPopup === true ) return ;
			handleSelect(selected, record);
		},
		onSelectAll: ( selected, selectedRows, changeRows ) => {
			if( isPopup === true ) return ;
			handleSelect( selected, selectedRows, true );
		},
        getCheckboxProps: (record) => ({
            name: record.key,
        })
	}), [isPopup, handleSelect]);

    return (
        <div ref={tableContainerRef}>
            <Checkbox.Group
                value={checkedList}
                options={options}
                className='mb-1'
                onChange={(value) => {
                    setCheckedList(value);
                }}
            />
            <Table 
                dataSource={records}
                columns={newColumns}
                pagination={false}
                tableLayout='auto'
                scroll={{ x: 'max-content', y: scrollY }}
                rowSelection={rowSelection}
                style={{ height: '58vh' }}
            />
        </div>
    );
}

export default AccueilTable;