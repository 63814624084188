import React, { useEffect, useState } from "react";

import { ReactComponent as Translation } from "assets/translation.svg";
import { TRANSLATES } from "constants";
import { useTranslate } from "contexts";

import { Dropdown, Button, Space } from "antd";

export const FloatButton = (  ) => {
    const [ dropdownItems, setDropdownItems ] = useState([]);
    const [ floatButtonIsOpen, setFloatButtonIsOpen ] = useState(false);

    const { lang, setLang } = useTranslate();

    useEffect(() => {
        let items = [];
        TRANSLATES.forEach(el => {
            const { key, value, icon } = el;
            items.push({
                label: (
                    <Space align="center" size={6}>
                        <Button shape="circle" style={{ height: 42, width: 42 }} onClick={() => handleChangeLanguage(value)} icon={icon}></Button>
                        {/* { value.toUpperCase() } */}
                    </Space>
                ),
                key: key
            })
        })
        setDropdownItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeLanguage = ( value ) => {
        setTimeout(() => {
            setFloatButtonIsOpen(!floatButtonIsOpen);
            setLang(value);
        }, 0)
    }

    return(
        <div
            style={{
                zIndex: 98,
                position: 'fixed',
                bottom: 10,
                right: 10
            }}
        >
            <div className="d-flex justify-content-center">
                <span style={{fontWeight: 'bold'}}>{lang.toUpperCase()}</span>
            </div>
            <Dropdown
                menu={{ items: dropdownItems }}
                placement="bottom"
                arrow
            >
                <Button 
                    type="primary" 
                    shape="circle" 
                    icon={<Translation width={42} height={42} />} 
                    style={{ height: 46, width: 46, backgroundColor: '#20a8d8' }}
                ></Button>
            </Dropdown>
        </div>
    );
}