import React, { useEffect, useRef } from 'react';

import { Spinner } from 'reactstrap';

import { useTranslation } from "contexts";

const PayerVendor = ({ ...props }) => {
    const { formConfig } = props;
    const formRef = useRef();
    const translate = useTranslation();

    useEffect(() => {
        var formEl = formRef.current ;
        if( formEl ) {
            setTimeout(() => {
                formEl.submit() ;
            }, 100);
        }
    }, [])
    
    return (
        <>
			<div style={{ display:'table', padding:'20px' }}>
				<span style={{ display:'table-cell', textAlign:'center', verticalAlign:'middle' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} />
				</span>
			</div>
            <form action={formConfig.action} ref={formRef} method="post">
				{
                    Object.keys(formConfig.fields).map((fieldName) => { 
                        let fieldValue = formConfig.fields[fieldName] ;
                        return (
                            <input 
                                key={fieldName}
                                name={fieldName}
                                type="hidden"
                                value={fieldValue}
                            />
                        );
				    })
                }
				<button style={{ display:'none' }} type="submit">{ translate("MiscSubmit") }</button>
			</form>
        </>
    );
}

export default PayerVendor ;