import React from 'react';

import { Label } from 'reactstrap';
import { Card, Col, Row, Space } from "antd";
import { Field, Form } from "formik";

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

const PayerForm = ( ) => {
    const translate = useTranslation();

    return (
        <Form>
            <Row gutter={[0, 16]}>
                <Col xs={24}>
                    <Card
                        type='inner'
                        title={(
                            <Row>
                                <Col xs={24}>
                                    <strong>{ translate("HomeModalPaidFormContactDetails") }</strong>
                                </Col>
                                <Col xs={24}>
                                    <small>{ translate("HomeModalPaidFormInformationMatchIdentityPayer") }</small>
                                </Col>
                            </Row>
                        )}
                    >
                        <Space direction="vertical" style={{ display: 'flex' }}>
                            <Row align="middle">
                                <Col xs={24} lg={4}>
                                    <Label className="div-form-label">{ translate("NewMessageLastname") }</Label>
                                </Col>
                                <Col xs={24} lg={20}>
                                    <Field component={InputWithFeedback} type="text" name="adr_nom"></Field>
                                </Col>
                            </Row>

                            <Row align="middle">
                                <Col xs={24} lg={4}>
                                    <Label className="col-form-label">{ translate("MiscAddress") }</Label>
                                </Col>
                                <Col xs={24} lg={20}>
                                    <Field component={InputWithFeedback} type="text" name="adr_adr1"></Field>
                                </Col>
                            </Row>

                            <Row align="middle">
                                <Col xs={24} lg={4}>
                                    <Label className="col-form-label">{ translate("NewMessageAdditionalInformation") }</Label>
                                </Col>
                                <Col xs={24} lg={20}>
                                    <Field component={InputWithFeedback} type="text" name="adr_adr2"></Field>
                                </Col>
                            </Row>

                            <Row align="middle" gutter={16}>
                                <Col xs={24} lg={4}>
                                    <Label className="col-form-label">{ translate("NewMessageZipCode") }</Label>
                                </Col>
                                <Col xs={24} lg={4}>
                                    <Field component={InputWithFeedback} type="text" name="adr_cp" ></Field>
                                </Col>
                                <Col xs={24} lg={2}>
                                    <Label className="col-form-label">{ translate("NewMessageCity") }</Label>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Field component={InputWithFeedback} type="text" name="adr_ville"></Field>
                                </Col>
                                <Col xs={24} lg={2}>
                                    <Label className="col-form-label">{ translate("NewMessageCountry") }</Label>
                                </Col>
                                <Col xs={24} lg={4}>
                                    <Field component={InputWithFeedback} type="text" name="adr_pays" readOnly></Field>
                                </Col>
                            </Row>

                            <Row align="middle" gutter={16}>
                                <Col xs={24} lg={4}>
                                    <Label className="col-form-label">{ translate("MiscTelephoneNumber") }</Label>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <Field component={InputWithFeedback} type="text" name="adr_tel"></Field>
                                </Col>
                                <Col xs={24} lg={2}>
                                    <Label className="col-form-label">{ translate("MiscEmail") }</Label>
                                </Col>
                                <Col xs={24} lg={10}>
                                    <Field component={InputWithFeedback} type="text" name="adr_email"></Field>
                                </Col>
                            </Row>
                        </Space>
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card
                        type='inner'
                        title={(
                            <Row>
                                <Col xs={24}>
                                    <strong>{ translate("MiscPayment") }</strong> 
                                </Col>
                                <Col xs={24}>
                                    <small>{ translate("HomeModalPaidFormPreSelectedAmount") }</small>
                                </Col>
                            </Row>
                        )}
                    >
                        <Space direction="vertical" style={{ display: 'flex' }}>
                            <Row align="middle">
                                <Col xs={24} lg={4}>
                                    <Label>{ translate("MiscAmountToBePaid") } (€)</Label>
                                </Col>
                                <Col xs={24} lg={20}>
                                    <Field component={InputWithFeedback} type="text" name="pay_amount"></Field>
                                </Col>
                            </Row>

                            <Row align="middle">
                                <Col xs={24} lg={4}>
                                    <Label>{ translate("NewMessageComment") } <small>({ translate("MiscOptionnal").toLowerCase() })</small></Label>
                                </Col>
                                <Col xs={24} lg={20}>
                                    <Field component={InputWithFeedback} type="textarea" name="pay_comment"></Field>
                                </Col>
                            </Row>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
}

export default PayerForm ;