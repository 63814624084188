import React, { useEffect } from "react";
import {
    HashRouter as Router, 
    Navigate,
    Route, 
    Routes
} from 'react-router-dom';

import { 
    FloatButton,
    Loading,
    Navbar,
    PublicRoutes, 
    ProtectedRoutes 
} from "components";
import { useAuth } from "contexts";
import { publicRoutes, protectedRoutes } from "routes";

import './App.scss';

export const App = (  ) => {
    const { auth, appIsReady } = useAuth();

    useEffect(() => {
        document.title = "Portail Recouveo";
    }, [])

    if( !appIsReady ) return <Loading />;
    return (
        <>
            { auth.isDev === true ? ( <div className="devbanner"></div> ) : null }
            <Router>
                <div className="app">
                    <Navbar /> 
                    <div className="app-body">
                        <FloatButton />
                        <Routes>
                            <Route path="/" element={<Navigate to={ auth.isAuth ? "/accueil" : "/login" } />} />
                            <Route element={<PublicRoutes />}>
                                {
                                    publicRoutes.map((r, i) => {
                                        const { path, element } = r;
                                        return(
                                            <Route key={i} path={path} element={element}/>
                                        );
                                    })
                                }
                            </Route> 
                            
                            <Route element={<ProtectedRoutes />}>
                                {
                                    protectedRoutes.map((r, i) => {
                                        const { path, element } = r;
                                        return(
                                            <Route key={i} path={path} element={element}/>
                                        );
                                    })
                                }
                            </Route> 

                            <Route path="*" element={<div>Page 404</div>}/>
                        </Routes>
                    </div>
                    <div className="app-footer" style={{ height: '10px' }}></div>
                </div>
            </Router>
        </>
    );
}