import React, { useEffect, useState } from 'react';

import { Alert } from 'reactstrap' ;

import { useTranslation } from "contexts";

import classNames from 'classnames/bind';

const PayerAlert = ({ ...props }) => {
    const { errorMessage, errorColor } = props;

    const [ alterColor, setAlterColor ] = useState(null);

    const translate = useTranslation();

    var classes = classNames({
        payeralert: true
    });

    useEffect(() => {
        let altColor ;
        switch( errorColor ) {
            case 'red' :
                altColor = 'danger' ;
                break ;
            case 'green' :
                altColor = 'success' ;
                break ;
            default :
                altColor = 'primary' ;
                break ;
        }

        setAlterColor(altColor);
    }, [errorColor])

    return (
        <div className={classes}>
            <Alert color={alterColor}>
                <div className="payeralert-text">
                    {errorMessage}
                </div>
            </Alert>
        </div>
    );
}

export default PayerAlert ;