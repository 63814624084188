import React, { useState } from "react";

import { Field, Form, Formik } from "formik";
import { Button, FormGroup, Label } from 'reactstrap';
import { Col, Row, Space } from "antd";
import * as Yup from "yup";


import { InputWithFeedback } from "components";
import { useTranslate, useTranslation, useApp } from "contexts";
import { createPostRequest, isNonEmpty } from "Utils/Common";

import UploadComponent from "./UploadComponent";
import PaymentScheduler from "./PaymentScheduler";

import AddCommentModal from "views/Recouveo/NouveauMessage/Modals/AddCommentModal";
import ConfirmModal from "views/Recouveo/NouveauMessage/Modals/ConfirmModal";
import ErrorModal from "views/Recouveo/Modals/ErrorModal";

import PaymentBlock from "./_PaymentBlock";
import ContactDetailBlock from "./_ContactDetailBlock";

function Delay(props) {
	const { uploadFiles, addFiles } = props;

	const [ files, setFiles ] = useState([]);
	const [ showModal, setShowModal ] = useState(false);
	const [ currentFile, setCurrentFile ] = useState(null);
	const [ showEcheance, setShowEcheance ] = useState(false);
	const [ showError, setErr ] = useState(false);
	const [ errorTxt, setErrTxt ] = useState(null);
	const [ echValues, setEchValues ] = useState(null);
	const [ hasErrors, setEchErrors ] = useState(false);
	const [ showConfirmModal, setConfirmModal ] = useState(false);

    const { lang } = useTranslate();
    const translate = useTranslation();
    const { config } = useApp();

	const addFilesToState = (e) => {
		setFiles(addFiles(e, files));
	}
	const setFileNewName = (name, i) => {
		if (files[i] === undefined || files[i] === null) return;
		files[i].newName = name;
	}

	const setPaymentSchedulerValues = (values) => {
		setEchValues(values);
	}

    const currentDate = new Date() ;
    let dateMax = new Date() ;
    dateMax.setMonth(dateMax.getMonth() + 1) ;

    const validationSchema = Yup.object().shape({
        tel: Yup
            .string()
            .required( translate("MiscFieldRequired") )
            .matches("^(\\+?\\d{0,4})?\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{4}\\)?)$", translate("MiscTelephoneNumberMustBeValid")),
        email: Yup
            .string()
            .required( translate("MiscFieldRequired") )
            .matches("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$", translate("MiscEmailAddressMustBeValid")),
        delayDate: Yup
            .date()
            .required( translate("MiscFieldRequired") )
            .min(currentDate, translate("MiscDateEarlierThanCurrent"))
            .max(dateMax, translate("MiscPaymentDateCannotOneMonthAway")),
        payType: Yup
            .string()
            .required( translate("MiscFieldRequired") ),
        echeances: Yup
            .string()
            .required( translate("MiscFieldRequired") )
    })

	return (
        <>
            {
                isNonEmpty(config) &&
                    <div className={"form"}>
                        <Formik
                            initialValues={{
                                comment: "",
                                amountDue: new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(config["amountDue"]),
                                delayDate: "",
                                name: config["accName"],
                                newName: "",
                                siren: config["siren"],
                                tel: config["tel"].replace(/\D/g, ''),
                                email: config["email"],
                                dueSum: "",
                                files: [],
                                payType: "",
                                prefContact: "",
                                echeances: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                let title = translate("NewMessageRequestForDelay");
                                let text;
                                if( values.delayDate !== "" ) {
                                    if( showEcheance === true ) {
                                        text = translate("NewMessageFirstDueDate") + ": " + new Date(values.delayDate).toLocaleDateString(lang) + "\r\n";
                                    } else {
                                        text = translate("NewMessagePaymentDateOfTotalAmount") + ": " + new Date(values.delayDate).toLocaleDateString(lang) + "\r\n";
                                    }
                                }
                                text += translate("NewMessageMeansOfPayment") + ": " + values.payType + "\r\n" +
                                        translate("NewMessageContactIdentityCompleted") + ": " + values.newName + "\r\n" +
                                        translate("MiscTelephoneNumber") + ": " + values.tel + "\r\n" +
                                        translate("MiscContactPreferencesShort") + ": " + values.prefContact + "\r\n" +
                                        translate("MiscEmail") + ": " + values.email + "\r\n" +
                                        translate("NewMessageComment") + ": " + values.comment + "\r\n";
                                if (showEcheance === true) {
                                    let ech0 = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(echValues["echValue0"]) ;
                                    let echNext = new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(echValues["echValue1"]) ;
                                    text += translate("NewMessageFirstDeadline") + ": " + ech0 + "€ \r\n";
                                    text += translate("NewMessageNextDeadlines") + ": " + echNext + "€ \r\n";
                                }
                                if (props.factSelected.length > 0){
                                    text += translate("NewMessageAffectedInvoices") + ": " ;
                                    props.factSelected.forEach((rec) => {
                                        text += rec["record_ref"] + ", " ;
                                    })
                                    text = text.slice(0, -2) ;
                                    text += "\r\n" ;
                                }
                                let error = false;

                                if (files.length > 0) {
                                    uploadFiles(files).then(response => {
                                        response.forEach((res, i) => {
                                            if (res.data !== null && res.data.success !== true) {
                                                error = true;
                                            }
                                        });
                                        if (error === true) {
                                            props.formSent("-1");
                                            return;
                                        }
                                        text = text + translate("NewMessageAttachments") + ": \r\n";
                                        files.forEach((file, i) => {
                                            if (file.newName !== "") text = text + file.newName + "\r\n";
                                            else text = text + file.name + "\r\n";

                                        });
                                        let msgAction = {
                                            "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                            "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                        };
                                        createPostRequest("tchat_post_formulaire", {
                                            "file_filerecord_id": config["fileOpenFilerecordId"],
                                            "data": JSON.stringify(msgAction)
                                        }).then(response => {
                                            if (response.data.success !== true) {
                                                props.formSent("-1");
                                            } else props.formSent("7");

                                        })

                                    }).catch(err => {
                                        error = true;
                                        props.addResult(false);
                                        props.formSent("-1");
                                    });
                                } else {
                                    let msgAction = {
                                        "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                        "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                    };
                                    createPostRequest("tchat_post_formulaire", {
                                        "file_filerecord_id": config["fileOpenFilerecordId"],
                                        "data": JSON.stringify(msgAction)
                                    }).then(response => {
                                        if (response.data.success !== true) {
                                            props.formSent("-1");
                                        } else props.formSent("7");
                                    })
                                }
                            }}
                            onReset={ values => props.onReset() }
                        >
                            {({
                                setFieldValue,
                                submitForm
                            }) => (
                                <Form>
                                    <AddCommentModal 
                                        isOpen={showModal} content={currentFile !== null ? files[currentFile].comment : ""}
                                        toggle={() => setShowModal(!showModal)}
                                        onChange={(e) => {
                                            if (e === null) {
                                                setShowModal(false);
                                                return;
                                            }
                                            files[currentFile].comment = e.target.value;
                                        }}
                                    />
                                    <ConfirmModal 
                                        isOpen={showConfirmModal} toggle={() => setConfirmModal(!showConfirmModal)}
                                        validate={() => {
                                            setConfirmModal(false);
                                            submitForm();
                                        }}
                                    />
                                    <ErrorModal 
                                        isOpen={showError} 
                                        error={errorTxt} 
                                        toggle={() => setErr(!showError)}
                                    />
                                    <Row gutter={[16,16]}>
                                        <Col xs={24} lg={12}>
                                            <Space direction="vertical">
                                                <Row align="middle">
                                                    <Col xs={24} lg={12}>
                                                        <Label>{ translate("NewMessageYourClaim") }</Label>
                                                    </Col>
                                                    <Col xs={24} lg={12}>
                                                        <Field component={InputWithFeedback} type="text" name="amountDue" disabled={true}/>
                                                    </Col>
                                                </Row>

                                                <Row align="middle">
                                                    <Col xs={24} lg={12}>
                                                        <Label className="control-label">{ translate("NewMessageWishToPay") }</Label>
                                                    </Col>
                                                    <Col xs={24} lg={12}>
                                                        <FormGroup check className="radio">
                                                            <Field 
                                                                component={InputWithFeedback} 
                                                                type="radio" 
                                                                id="numPayment" 
                                                                name="echeances" 
                                                                value="0"
                                                                onChange={(e) => {
                                                                    setFieldValue("echeances", e.target.value);
                                                                    setShowEcheance(false);
                                                                }}
                                                            />
                                                            <Label check className="form-check-label">{ translate("NewMessageInOneGo") }</Label>
                                                        </FormGroup>
                                                        <FormGroup check className="radio">
                                                            <Field 
                                                                component={InputWithFeedback} 
                                                                type="radio" 
                                                                id="dueTo2" 
                                                                name="echeances" 
                                                                value="1"
                                                                onChange={(e) => {
                                                                    setFieldValue("echeances", e.target.value);
                                                                    setShowEcheance(true);
                                                                }}
                                                            />
                                                            <Label check className="form-check-label">{ translate("NewMessageInSeveralInsTallments") }</Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {
                                                    showEcheance === true 
                                                    ?
                                                        <PaymentScheduler 
                                                            change={setPaymentSchedulerValues} 
                                                            amount={config["amountDue"]}
                                                            setEchErrors={(value) => {
                                                                setEchErrors(value)
                                                            }}
                                                        />
                                                    : 
                                                        null
                                                }

                                                <Row align="middle">
                                                    <Col xs={24} lg={12}>
                                                        <Label className="control-label">
                                                            {
                                                                showEcheance === true 
                                                                ? 
                                                                    <>{ translate("NewMessageFirstDueDate") }</>
                                                                : 
                                                                    <>{ translate("NewMessageWishToPayOn") }</>
                                                            }
                                                        </Label>
                                                    </Col>
                                                    <Col xs={24} lg={12}>
                                                        <Field component={InputWithFeedback} type="date" name="delayDate"/>
                                                    </Col>
                                                </Row>

                                                <PaymentBlock name="payType" />

                                                <ContactDetailBlock />
                                            </Space>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <UploadComponent 
                                                files={files} 
                                                errors={showError} 
                                                addFiles={(e) => {
                                                    if (e.target.files.length > 3 || files.length + e.target.files.length > 3) {
                                                        setErrTxt( translate("NewMessageModalTooMuchFiles") );
                                                        setErr(true);
                                                        return;
                                                    }
                                                    let issue = false;
                                                    const filesUploaded = Object.values(e.target.files);
                                                    filesUploaded.forEach((file, i) => {
                                                        if (file.size >= 10000000) {
                                                            setErrTxt( translate("NewMessageModalTooMuchSize") )
                                                            setErr(true);
                                                            issue = true;
                                                        }
                                                    });
                                                    if (issue === true) return;
                                                    addFilesToState(e);
                                                    setFieldValue("files", files);
                                                }} 
                                                setFileNewName={setFileNewName} 
                                                setFiles={setFiles} 
                                                setFieldValue={setFieldValue}
                                                setShowModal={setShowModal} 
                                                setCurrentFile={setCurrentFile}
                                                currentFile={currentFile}
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xs="6">
                                            <FormGroup row>
                                                <Col xs="6">
                                                V    <Label>{ translate("NewMessageYourClaim") }</Label>
                                                </Col>
                                                <Col xs="6">
                                                   V <Field component={InputWithFeedback} type="text" name="amountDue" disabled={true}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col xs="6">
                                                   V <Label className="control-label">{ translate("NewMessageWishToPay") }</Label>
                                                </Col>
                                                <Col xs="6">
                                                    V<FormGroup check className="radio">
                                                        <Field 
                                                            component={InputWithFeedback} 
                                                            type="radio" 
                                                            id="numPayment" 
                                                            name="echeances" 
                                                            value="0"
                                                            onChange={(e) => {
                                                                setFieldValue("echeances", e.target.value);
                                                                setShowEcheance(false);
                                                            }}
                                                        />
                                                        <Label check className="form-check-label">{ translate("NewMessageInOneGo") }</Label>
                                                    </FormGroup>
                                                    V<FormGroup check className="radio">
                                                        <Field 
                                                            component={InputWithFeedback} 
                                                            type="radio" 
                                                            id="dueTo2" 
                                                            name="echeances" 
                                                            value="1"
                                                            onChange={(e) => {
                                                                setFieldValue("echeances", e.target.value);
                                                                setShowEcheance(true);
                                                            }}
                                                        />
                                                        <Label check className="form-check-label">{ translate("NewMessageInSeveralInsTallments") }</Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                            {
                                                showEcheance === true 
                                                ?
                                                  V  <PaymentScheduler 
                                                        change={setPaymentSchedulerValues} 
                                                        amount={config["amountDue"]}
                                                        setEchErrors={(value) => {
                                                            setEchErrors(value)
                                                        }}
                                                    />
                                                : 
                                                    null
                                            }
                                            <FormGroup row>
                                                <Col xs="6">
                                                  V  <Label className="control-label">
                                                        {
                                                            showEcheance === true 
                                                            ? 
                                                                <>{ translate("NewMessageFirstDueDate") }</>
                                                            : 
                                                                <>{ translate("NewMessageWishToPayOn") }</>
                                                        }
                                                    </Label>
                                                </Col>
                                                <Col xs="6">
                                                   V <Field component={InputWithFeedback} type="date" name="delayDate"/>
                                                </Col>
                                            </FormGroup>
                                            <PaymentBlock name="payType" />
                                            <ContactDetailBlock />
                                        </Col>
                                        <UploadComponent 
                                            files={files} 
                                            errors={showError} 
                                            addFiles={(e) => {
                                                if (e.target.files.length > 3 || files.length + e.target.files.length > 3) {
                                                    setErrTxt( translate("NewMessageModalTooMuchFiles") );
                                                    setErr(true);
                                                    return;
                                                }
                                                let issue = false;
                                                const filesUploaded = Object.values(e.target.files);
                                                filesUploaded.forEach((file, i) => {
                                                    if (file.size >= 10000000) {
                                                        setErrTxt( translate("NewMessageModalTooMuchSize") )
                                                        setErr(true);
                                                        issue = true;
                                                    }
                                                });
                                                if (issue === true) return;
                                                addFilesToState(e);
                                                setFieldValue("files", files);
                                            }} 
                                            setFileNewName={setFileNewName} 
                                            setFiles={setFiles} 
                                            setFieldValue={setFieldValue}
                                            setShowModal={setShowModal} 
                                            setCurrentFile={setCurrentFile}
                                            currentFile={currentFile}
                                        />
                                    </Row> */}
                                    <Row>
                                        <div style={{width: '100%', margin: '0px auto', marginTop: '10px'}} className="text-center">
                                            <div style={{color: "red"}}>{ translate("MiscFieldsRequired") }</div>
                                            <br/>
                                            <Button 
                                                color="primary" 
                                                onClick={() => {
                                                    if (showEcheance === true) {
                                                        if (echValues === null) {
                                                            setErrTxt( translate("NewMessageYourPaymentScheduleIsNotFilled") );
                                                            setErr(true);
                                                            return;
                                                        }
                                                        if (hasErrors === true) {
                                                            setErrTxt( translate("NewMessageErrorsArePresentInYourPaymentSchedule") );
                                                            setErr(true);
                                                            return;
                                                        }
                                                    }
                                                    setConfirmModal(true);
                                                }}
                                            >
                                                { translate("MiscSendMessage") }
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
            }
        </>
	)
}

export default Delay;